#page-wrapper {
  background-color: #f3f3f4;
}

.b-shadow {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
}
.hoverEdit:hover {
  stroke: #2db27c;
}

.navbar-fixed-top,
.navbar-static-top {
  background: #fff;
}

/* dashborad top starts*/
.d-top {
  background: #fdfdfd;
  border-radius: 24px;
  padding: 12px 15px;
  margin: 30px 0px;
}

.d-top-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channelBox {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.d-top-left .channelBox span {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.38px;
  color: #384c5f;
  display: block;
}

.d-top-left .channelBox p {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.38px;
  color: #384c5f;
  padding-bottom: 4px;
}

.channelBtn {
  width: 100%;
  max-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2db27c;
  column-gap: 12px;
  color: #fff;
  border-radius: 18px;
  font-weight: 700;
  font-size: 14px;
  margin-left: 15px;
  cursor: pointer;
}

.channelBtn:hover {
  color: #fff;
  background-color: #446789;
}

.d-top-right {
  display: flex;
  justify-content: flex-end;
}

.d-top .d-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-top .d-row .cstm-col-md-6 {
  width: 100%;
  max-width: 50%;
}

/* dashborad top end*/
.wrapper__content .channel__left {
  background: #fdfdfd;
  border-radius: 24px;
  padding: 30px 0px 0px;
}

.ch-head p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
}

.ch-head span {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.channel__top {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.channel__left .channer_ul {
  list-style: none;
}

.channel__left .channer_ul li {
  background-color: #fff;
  padding: 14px 20px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 2px solid transparent;
  cursor: pointer;
}

.channel__left .channer_ul li:hover {
  background: linear-gradient(121.56deg, #fcfcfc 8.05%, #f5f4f9 82.51%);
  border-left: 2px solid #304e75;
}

.channer_ul li .li_left .letter {
  width: 100%;
  max-width: 42px;
  height: 42px;
  background: linear-gradient(0deg, #f6f5f9, #f6f5f9);
  border: 0.591162px solid rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channer_ul li .li_left .letter p {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #b9cde7;
}

.channer_ul li .li_left {
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;
  width: 100%;
  max-width: 85%;
  align-items: center;
}

.channer_ul li .li_left .m-loc p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #464255;
}

.channer_ul li .li_left .m-loc span {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.wrapper__content .m-row {
  display: flex;
  margin: unset;
  justify-content: space-between;
}

/* cafe right side */
.cafe_side {
  padding: 30px;
  background: #fdfdfd;
  border-radius: 24px;
  height: 700px;
}
.cafe_side .no-channel {
  text-align: center;
  font-size: 18px;
}
.wrapper__content .cstm-col-md-4 {
  width: 100%;
  max-width: 25%;
  background: #fdfdfd;
  border-radius: 24px;
  position: relative;
  height: 700px;
}

.wrapper__content .channel-cstm-col-md-8 {
  width: 100%;
  max-width: 74%;
  position: relative;
}

.cafe_side .cafe-top {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cafe_side .cafe-top .cafe-top-left p {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
  padding-bottom: 8px;
}

.cafe_side .cafe-top .cafe-top-left span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.cafe_side .cafe-top-right .search-box {
  position: relative;
  display: inline-block;
  height: 45px;
  width: 50%;
}

.cafe_side .cafe-top-right .search-input {
  padding-left: 18px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: #f5f5f5;
  border-radius: 18px;
}

.cafe_side .cafe-top-right .search-input::placeholder {
  color: #a3a3a3;
  font-size: 13px;
}

.cafe_side .cafe-top-right .search-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.cafe_side .cafe-top-right {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
}

.cafe_side .cafe-top-right .Recentbtn select {
  width: 130px;
  height: 45px;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right;
  background-origin: content-box;
  outline: none;
  background-image: url("../Assets/dropdowm.png");
  background-size: 14%;
  background-color: #f5f5f5;
  border-radius: 18px;
  padding: 0px 15px;
  border: none;
  font-weight: 500;
  font-size: 13px;
  color: #464255;
  cursor: pointer;
}

.cafe_side .cafe-top-right .menue-bar {
  cursor: pointer;
}

.cafe_side .devicesTable table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
}

.cafe_side .devicesTable th,
.cafe_side .devicesTable td {
  padding: 15px 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.cafe_side .devicesTable th {
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
  font-weight: 400;
}

.cafe_side .devicesTable td {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #464255;
}

.cafe_side .devicesTable table {
  background-color: #fdfdfd;
}

td:last-child button {
  margin-left: 5px;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.cafe_side .devicesTable input[type="checkbox"] {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
}

.cafe_side .devicesTable input[type="checkbox"] {
  display: none;
}

.cafe_side .devicesTable input[type="checkbox"] + label {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
}

.cafe_side .devicesTable input[type="checkbox"] + label:hover {
  box-shadow: 0 0 5px rgb(65, 126, 192);
}

.cafe_side .devicesTable input[type="checkbox"] + label::before {
  content: "\2713";
  font-weight: bold;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cafe_side .devicesTable input[type="checkbox"]:checked + label::before {
  color: #417ec0;
}

.cafe_side .devicesTable input[type="checkbox"]:checked + label {
  border-color: #417ec0;
}

.devicesTable table tbody td .addDevices {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #c4c4c4;
}

.devicesTable table tbody td label,
.devicesTable table thead td label {
  margin-bottom: 0px;
}

.devicesTable table tbody tr:first-child {
  background-color: #fafcff;
}

label {
  margin-bottom: 0px;
}

.devicesTable table tbody td input[type="text"] {
  border: 0.877613px solid #eceaf3;
  border-radius: 15.797px;
  outline: none;
  padding: 8px;
  background: #fafcff;
}

.devicesTable table tbody td .errorMsg {
  border: 1px solid #fd3c4f !important;
  position: relative;
}

.devicesTable table tbody td .errorMsg::placeholder {
  color: #f05b5b !important;
}

.cafe_side .devicesTable table tbody td::placeholder {
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
}

.cafe_side .devicesTable table tbody td.totaldevices {
  color: #417ec0;
  cursor: pointer;
  position: relative;
}

.cafe_side .devicesTable table tbody td.totaldevices .td-dropDown {
  position: absolute;
  box-shadow: 0px 10px 24px rgba(48, 78, 117, 0.16);
  right: 0;
  background: #fdfdfd;
  border-radius: 24px;
  z-index: 100;
  list-style: none;
  width: 100%;
  min-width: 300px;
  padding-bottom: 20px;
}

.td-dropDown .topDevice_li {
  background-color: #fdfdfd;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 24px;
}

.dev__top {
  display: flex;
  flex-direction: column;
}

.td-dropDown li:first-child {
  padding: 0px;
}

.td-dropDown .loadMore {
  padding: 8px 0px;
  width: 100%;
  max-width: 142px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #b6b6b6;
  background: #ebebeb;
  border: 1px solid #eeeeee;
  border-radius: 18px;
  margin: -15px auto 0;
  column-gap: 7px;
}

.td-dropDown li {
  background-color: #fff;
  padding: 15px 30px;
  border-bottom: 1px solid #eeeeee;
}

.td-dropDown li p {
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #464255;
  padding-bottom: 5px;
}

.td-dropDown .topDevice_li p {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
}

.flex {
  display: flex;
  justify-content: space-betweena;
  align-items: center;
}

.cafe_side .devicesTable table tbody tr:hover {
  box-shadow: 0px 10px 24px rgba(48, 78, 117, 0.16);
  background-color: #fff;
  border-left: 5px solid #417ec0;
  z-index: 99;
}

.cafe_side .devicesTable table tbody tr {
  position: relative;
  transition: transform 0.2s ease-in-out;
  border-left: 5px solid transparent;
}

.pagingation .showData p {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #304e75;
}

.pagingation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 15px;
  right: 38px;
}

.pagingation .pagnum div {
  background-color: #304e75;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagingation .pagnum {
  display: flex;
  column-gap: 7px;
}

.btmDiv {
  position: absolute;
  bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 24px 24px;
  width: 100%;
}

.btmDiv .new-channel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  column-gap: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #a3a3a3;
}

.error_td {
  position: relative;
}

/*tooltip */
.error_td .errortooltip {
  z-index: 110;
  width: 100%;
  max-width: 160px;
  padding: 10px 20px;
  background: linear-gradient(
      0deg,
      rgba(240, 91, 91, 0.21),
      rgba(240, 91, 91, 0.21)
    ),
    #ffffff;
  color: #e086d3;
  position: absolute;
  border-radius: 9px;
  transform: translateY(9px);
}

.errortooltip p {
  font-size: 10px;
  line-height: 15px;
  color: #fd3c4f;
}

.errortooltip::after {
  content: " ";
  /* width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12.5px 0 12.5px;
    border-color: #333 transparent transparent transparent;
    position: absolute;
    left: 45%;
    top: -5px;
    transform: rotate(-180deg); */
  position: absolute;
  margin-top: -6px;
  margin-left: -5px;
  left: 45%;
  top: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fde0e0;
  transform: rotate(-360deg);
}
.svg-div {
  display: inline-block;
  cursor: pointer;
}
.wrapper__content {
  margin-bottom: 20px;
}
#li__right {
  position: relative;
}
.li-right {
  position: relative;
}

.right_li_dropDown {
  position: absolute;
  right: 16px;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  background-color: #fff;
  width: 32%;
  min-width: 130px;
  border-radius: 8px;
}

.right_li_dropDown p {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding: 10px 10px 7px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #464255;
}
button {
  outline: none;
  border: none;
}
.right_li_dropDown p svg {
  margin-right: 14px;
}

.right_li_dropDown p:hover {
  background: linear-gradient(121.56deg, #fcfcfc 8.05%, #f5f4f9 82.51%);
}
.right_li_dropDown p.textred {
  color: #fd3c4f;
  font-weight: 600;
}
.side_bar {
  height: 516px;
  overflow-y: auto;
}
button.createbtn {
  border-bottom-right-radius: 24px;
}

button.cancelbtn {
  border-bottom-left-radius: 24px;
}

.cursor-pointer {
  cursor: pointer;
}
.pagination-buttons {
  display: flex;
  gap: 20px;
  display: flex;
  gap: 20px;
  align-items: center;
}
.pageNationBtn {
  background-color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #304e75;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-spin .ant-spin-dot {
  position: relative;
  width: 12em;
  height: 7em;
  color: #384c5f;
}
.ant-spin {
  align-items: center;
  display: flex;
  justify-content: center;
}
.background-color {
  background-color: #f9f9f9 !important;
}
tbody td .addDiv .anticon {
  font-size: 27px !important;
  display: inline-block !important;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-left: 11px;
  cursor: pointer;
  color: #a8a8a8;
}
.anticon:hover {
  color: #2db27c;
}
.spinner {
  height: 25px;
  margin-left: 10px;
  width: 25px;
  border: 2px solid;
  border-color: #001529 transparent #001529 transparent;
  border-radius: 50%;
  animation: spin 1.4s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.createbtn .spinner {
  align-items: center;
  height: 35px;
  width: 42px;
  border: 6px solid;
  border-color: #001529 transparent #001529 transparent;
  border-radius: 50%;
  animation: spin 1.4s linear infinite;
  margin-left: 40px;
}

.recent__Activity {
  position: relative;
  cursor: pointer;
}

.recentDropDown {
  width: 100%;
  /* display: none; */
  position: absolute;
  right: 0;
  box-shadow: 0px 5.02857px 15.0857px rgba(48, 78, 117, 0.14);
  background-color: #fff;
  min-width: 160px;
  z-index: 100;
  border-radius: 10px;
}

.recentDropDown p {
  border-bottom: 1px solid #eeeeee;
  padding: 12px 20px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
}

.recentDropDown p:last-child {
  border-bottom: unset;
}

.recentDropDown p:hover {
  background: #eeeeee;
}

.recentDropDown p.atoz {
  border-radius: 10px 10px 0px 0px;
}

.recentDropDown p.recent {
  border-radius: 0px 0px 10px 10px;
}

.recent-right .recent__Activity .recentDropDown img {
  object-fit: contain;
}
.brandDisplay {
  display: flex;
  gap: 10px;
  text-align: center;
  align-items: center;
}
.brandImg-c {
  width: 51px;
  height: 28px;
  border-radius: 25px;
  object-fit: contain;
}
.brandImg-i {
  width: 33px;
  height: 28px;

  border-radius: 25px;
  object-fit: contain;
}
