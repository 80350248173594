/* dashborad top end*/
.wrapper__content .channel__left {
  background: #fdfdfd;
  border-radius: 24px;
  padding: 30px 0px 0px;
}

.ch-head p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
}

.ch-head span {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.channel__top {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.channel__left .channel__top .c-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel__left .channel__top .c-right img {
  width: 80%;
  height: 80%;
}

.wrapper__content .m-row {
  display: flex;
  margin: unset;
  justify-content: space-between;
  margin: 0 20px;
}

/* cafe right side */

.wrapper__content .camp-custom-col-4 {
  width: 100%;
  max-width: 25%;
  background: #fdfdfd;
  border-radius: 24px;
  position: relative;
  height: 700px;
}

.wrapper__content .camp-col-8 {
  width: 100%;
  max-width: 74%;
  height: 700px;
}

.pagingation .showData p {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #304e75;
}

.pagingation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 170px;
}

.pagingation .pagnum a {
  background-color: #304e75;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagingation .pagnum {
  display: flex;
  column-gap: 7px;
}

.pagingation .pagnum a:first-child {
  background: rgba(48, 78, 117, 0.15);
  color: #304e75;
}

/* campaign */
.campaign {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
  margin-bottom: 5px;
}

.campaignWraper {
  background: #fdfdfd;
  border-radius: 24px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.campCol {
  flex: 1;
  padding: 10px;
}

.cam-title {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.cam-desc {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #464255;
  width: 100%;
  max-width: 190px;
}

.m-top {
  margin-top: 25px;
}

.paid-button {
  border: none;
  color: #5b9e7b;
  padding: 8px 17px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 14px;
  font-weight: 500;
  font-size: 14px;
  background: rgba(91, 158, 123, 0.15);
  margin-top: 20px;
}

.paid-button-wbg {
  border: none;
  color: #5b9e7b;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  background: transparent !important;
}

.prog-button {
  border: none;
  color: #ffbb54;
  padding: 8px 17px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  background: rgba(255, 187, 84, 0.15);
  border-radius: 21px;
  margin-top: 20px;
}
.prog-button-wbg {
  border: none;
  color: #ffbb54;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  background: transparent !important;
  border-radius: 21px;
}

.camp-col-btn {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.camp-col-btn .v-img {
  width: 25px;
  height: 25px;
  margin-top: 20px;
}

.inner-camp-col {
  margin-bottom: 20px;
}

.b-date {
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
  width: 100px;
}

.bdr-right {
  border-right: 1px solid #d0d6de;
}

.border-left {
  border-left: 1px solid #d0d6de;
}
.campCol {
  position: relative;
}

/* modal */
#c-modal-content {
  width: 100%;
  max-width: 300px;
  background-color: #fff;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #fdfdfd;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
}

/* Close Button */
.close {
  font-size: 20px;
  font-weight: bold;
  color: #666;
  cursor: pointer;
}

/* Overlay */

.modalBtn {
  display: flex;
}

button.createbtn {
  border-bottom-right-radius: 24px;
}

button.cancelbtn {
  border-bottom-left-radius: 24px;
}

.selectwrapper select {
  outline: none;
  border: none;
  padding-left: 10px;
  padding-right: 15px;
  width: 100%;
  height: 46px;
  color: #181820;
  appearance: none;
  background: url("../Assets/selectDrop.png");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: right;
  background-origin: content-box;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #eceaf3;
  border-radius: 18px;
}

.selectwrapper {
  position: relative;
}

.selectwrapper .absoluteImg {
  position: absolute;
  left: 9px;
  bottom: 22px;
  width: 22px;
  height: 22px;
}

.dateInput input {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #eceaf3;
  border-radius: 18px;
  outline: none;
}
.titlepad {
  padding-left: 11px !important;
  padding: 10px 11px !important;
}

.inputGroup input[type="text"],
input[type="date"] {
  font-size: 16px;
  line-height: 19px;
  color: #304e75;
  font-size: 12px;
}

textarea {
  border: 1px solid #eceaf3;
  background: #ffffff;
  border-radius: 18px;
  padding: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #a3a3a3;
}

.dateInput label {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}
.camp__btmDiv {
  position: a;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  display: block;
  text-align: center;
}
.camp__btmDiv .new-campaign {
  padding: 20px 0px;
  border: none;
  background-color: #fdfdfd;
}
.camp__btmDiv .new-campaign img {
  margin-right: 10px;
}

.not-started {
  border: none;
  color: rgba(185, 205, 231, 1);
  padding: 8px 17px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 14px;
  font-weight: 500;
  font-size: 14px;
  background: rgba(185, 205, 231, 0.15);
  margin-top: 20px;
}
.not-started-wbg {
  border: none;
  color: rgba(185, 205, 231, 1);
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 14px;
  font-weight: 500;
  background: transparent !important;
}
.c-a-dash {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: -253px;
  right: 38px;
}
.campCol.test.bdr-right {
  min-width: 185px;
  min-height: 164px;
}
.td-tittle {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95px;
}
.tbldata:hover .tooltiptext {
  display: block;
}
.tbldata {
  position: relative;
}
.tooltiptext {
  padding: 9px;
  position: absolute;
  background: #000;
  color: #fff;
  z-index: 9;
  display: none;
  top: -28px;
}
