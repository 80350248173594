#timeslotmodal {
  width: 100%;
  max-width: max-content;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.07);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #fdfdfd;
  border-radius: 24px;
}


Button .ts-m-close {
  font-size: 20px;
  color: #666;
  cursor: pointer;
  position: absolute;
  right: 40px;
}

#ts-m-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.ts-m-modalTop {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  padding-top: 20px;
  position: relative;
}

.ts-m-modalTop h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
}

.greytext {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #a3a3a3;
}
.greytext2{
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #a3a3a3;
  margin-left: 5px;
  margin-bottom: 10px;
}

.silverSlot,
.platinumSlot,
.goldSlot {
  margin-top: 30px;
  padding: 0px 30px;
}

.s-no-slots {
  display: flex;
  justify-content: center;
}

.s-no-slots span {
  background-color: #fbfbfb;
  width: 30px;
  height: 30px;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #c0c0c0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.s-no-slots span:hover {
  background-color: #f5f5f5;
}

.s-no-slots span.am-pm {
  background-color: #f0f3f7;
  color: #304e75;
}

.s-no-slots span.silver-color {
  background-color: #e7e6e6;
  color: #384c5f;
}

.s-no-slots span.Platinum-color {
  background-color: #a6a6a6;
  color: #fff;
}

.s-no-slots span.golden-color {
  background-color: #bf8f00;
  color: #fff;
}

.close__btn {
  padding: 22px 0px;
  width: 100%;
  display: block;
  outline: none;
  background: linear-gradient(121.56deg, #fcfcfc 8.05%, #f5f4f9 82.51%);
  border-top: 1px solid rgba(56, 76, 95, 0.08);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #a3a3a3;
  margin-top: 40px;
  border-radius: 0px 0px 24px 24px;
  cursor: pointer;
}
.close__btn:hover {
  color: red;
}
img.quest_img {
  width: 100%;
  max-width: 18px;
  height: 18px;
  margin-left: 0px;
}





.table-content table{
  background-color: #f0f3f7;
  width:  100%;
}
.table-content h3 {
  text-align: center;
  color: #A6A6A6;
  margin: 0;
  font-weight: 300;
}
td.days-one {
  color: #304E75;
  background-color: #E7E6E6;
}

td.days-two {
  background-color: #BF8F00;
  color: #fff;
}

td.days-three {
  background-color: #A6A6A6;
  color: #fff;
}

.table-content table td {
  padding: 5px;
}
.table-content {
  width: 50%;
  margin: auto;
}
#tenuremodal {
  width: 25%;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.07);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #fdfdfd;
  border-radius: 24px;
  
}
.inputFieled{
  border:none  !important;
  width: 230px;
  padding: 15px 15px;
  outline: none !important;
}
.imgStyle{
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.boaderinput{
  border-radius: 18px;
  border: 1px solid var(--light-theme-color-grey-3, #ECEAF3) !important;
  background: var(--light-theme-color-white, #FFF) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
}

.editContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin-top: 20px;
  margin-bottom: 80px;
  color: var(--light-theme-color-grey-6, #A3A3A3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.copyContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin-top: 20px;
  color: var(--light-theme-color-grey-6, #A3A3A3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}