.channel-add {
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.channelNotAdd{
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 102px 0px;
}
.channel-Add-Form {
  width: 100%;
  padding: 35px 20px;
  background-color: #fff;
  clear: both;
  margin-bottom: 15px;
  margin-top: 0px;
  background: #fff;
  border-radius: 20px;
}
.channel-Add-View {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  gap: 20px;
}
.channel-Add-View .Addbox {
  max-width: 100%;
}
.channel-Add-View .channelAddTitle {
  color: rgb(48, 78, 117);
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 15px;
}
.Form {
  display: flex;
  flex-direction: column;
  align-items: left;
}
.channelAddLable {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}
.channel-Add-View .Form .form-control {
  border: 1px solid #eceaf3;
  border-radius: 10px;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  background-image: none;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  color: inherit;
  display: block;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
.table .btn {
  background: #384c5f;
  border: none;
  color: #fff;
  padding: 6px 9px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  transition: 0.2s;
  display: inline-block;
  margin-top: 20px;
}
.topmenu {
  display: flex;
  justify-content: space-between;
}
.channel-Add-View .Addbox .table thead {
  color: #fff;
  text-align: center;
  background-color: #384c5f;
}
.channel-Add-View .Addbox .table tbody tr td {
  text-align: center;
  font-size: 15px;
}
@media (max-width: 997px) {
  .channel-Add-View {
    flex-wrap: wrap;
  }

  .Addbox {
    flex: none;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .channel-Add-Form {
    padding: 7px 1px;
    clear: both;
    margin-bottom: 15px;
  }
  .Ad-From-Group {
    margin-bottom: 1rem;
    width: 100%;
  }
}
