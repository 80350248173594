body {
  margin: 0px !important;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.clogin-screen {
  background-image: url("../Assets/login.webp");
  background-size: cover;
  position: relative;
  width: 100%;
  float: left;
  height: 100vh;
}

.clogin-screen .logo {
  position: absolute;
  width: 60%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.errMsg {
  color: red;
  margin-top: 10px;
  text-align: left;
}

.clogin-screen .login-area {
  width: 32%;
  max-width: 32%;
  margin: 0;
  float: right;
  background: #fff;
  padding: 60px;
  height: 100vh !important;
  position: relative;
}



.clogin-screen .login-area .title h1 {
  color: #304e75;
  position: relative;
  margin-top: 0;
  font-size: 30px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 15px;
}

.clogin-screen .login-area .title h1::after {
  content: "";
  height: 3px;
  width: 33px;
  background: #304e75;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.clogin-screen .login-area .title .sub-title {
  color: rgba(48, 48, 48, 0.7);
  text-align: left;
  font-size: 11px;
}

.clogin-screen .login-area label {
  color: #a3a3a3;
  float: left;
  text-align: left;
}

.formInput {
  border: solid 1px #eceaf3;
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  font-size: 0.9rem;
}

.clogin-screen .login-area .login-btn {
  background: #384c5f;
  border: none;
  color: #fff;
  padding: 15px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  margin-top: 20px;
  cursor: pointer;
  transition: 0.2s;
  text-transform: uppercase;
  width: 100%;
}

.clogin-screen .login-area .login-btn:hover {
  opacity: 0.8;
}

.clogin-screen .login-area .forgot-pass small {
  text-align: left;
  font-size: 12px;
  float: left;
  width: 100%;
  color: rgb(51, 122, 183);
  cursor: pointer;
  margin-top: 15px;
}

.clogin-screen .login-area .cpy-rght {
  float: left;
  width: 100%;
  text-align: left;
  margin-top: 40px;
  font-size: 11px;
  color: rgb(103, 106, 108);
}

.formContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.formLabel {
  color: #a3a3a3;
  float: left;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 13px;
}

@media (max-width: 997px) {
  .clogin-screen .logo {
    width: 50%;
  }

  .clogin-screen .login-area {
    padding: 30px;
    width: 44%;
    max-width: 44%;
    height: 91vh !important;
  }
}

@media (max-width: 767px) {
  .clogin-screen .logo {
    width: 100%;
  }

  .clogin-screen {
    background-size: contain;
  }

  .clogin-screen .login-area {
    width: 85%;
    max-width: 85%;
    background: rgba(255, 255, 255, 0.95);
    top: 35px;
    float: none;
    margin: auto;
    border-radius: 15px;
    height: 500px !important;
  }
  .clogin-screen .forgetHeight {
    width: 85%;
    max-width: 85%;
    background: rgba(255, 255, 255, 0.95);
    top: 35px;
    float: none;
    margin: auto;
    border-radius: 15px;
    height: 300px !important;
  }

  .clogin-screen .login-area .cpy-rght {
    margin-bottom: 0;
    float: none;
  }

  .clogin-screen .login-area .forgot-pass small {
    margin-bottom: 20px;
  }
}

@media (max-width: 420px) {
  .clogin-screen .login-area {
    width: 75%;
    max-width: 75%;
  }
}
