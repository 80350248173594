#m-modal-content {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #fdfdfd;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
}
.modalerrorText {
  text-align: center;
  color: #fd3c4f;
  font-size: small;
  font-weight: 500;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 10px;
}
.close {
  font-size: 20px;
  font-weight: bold;
  color: #666;
  cursor: pointer;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
}

.modalTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.modalTop h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
}

.forminput {
  padding: 20px;
}

.forminput .inputGroup label {
  display: block;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.forminput .inputGroup {
  margin-bottom: 12px;
}

.innerInput {
  position: relative;
}

.forminput .inputGroup .innerInput img {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.forminput .inputGroup input {
  width: 100%;
  padding: 10px 24px;
  background: #ffffff;
  border-radius: 15px;
  outline: none;
  border: 1px solid #eceaf3;
  padding-left: 35px;
}

.forminput .inputGroup .pl {
  padding-left: 12px;
}

.cancelbtn,
.createbtn {
  width: 100%;
  max-width: 50%;
  outline: none;
  border: none;
  padding: 18px;
  font-weight: 500;
  font-size: 14px;
  color: #a3a3a3;
  cursor: pointer;
}
.cancelbtn:hover {
  color: #fd3c4f;
}
.createbtn:hover {
  color: #2db27c;
}
.cancelbtn {
  border-right: 1px solid #d5dfe9;
}

.modalBtn {
  display: flex;
}

.errorMsg {
  border: 1px solid #fd3c4f !important;
  position: relative;
}

.errorMsg::placeholder {
  color: #f05b5b !important;
}
button.createbtn {
  border-bottom-right-radius: 24px;
}

button.cancelbtn {
  border-bottom-left-radius: 24px;
}
.innerInput .modalerrortooltip {
  z-index: 110;
  width: 100%;
  max-width: 160px;
  padding: 10px 20px;
  background: linear-gradient(
      0deg,
      rgba(240, 91, 91, 0.21),
      rgba(240, 91, 91, 0.21)
    ),
    #ffffff;
  color: #e086d3;
  position: absolute;
  border-radius: 9px;
  transform: translateY(9px);
  right: -48%;
  top: 0;
}

.modalerrortooltip p {
  font-size: 10px;
  line-height: 15px;
  color: #fd3c4f;
}

.modalerrortooltip::after {
  content: " ";
  position: absolute;
  margin-top: -6px;
  margin-left: -5px;
  left: -4px;
  top: 45%;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #fde0e0;
  transform: rotate(-360deg);
}
button.createbtn {
  border-bottom-right-radius: 24px;
}

button.cancelbtn {
  border-bottom-left-radius: 24px;
}
