.digitalBox {
    padding: 20px;
    background: #FDFDFD;
    height: 127px;
    border-radius: 24px;
    border: 2px solid #eceaf3;
}

.b-shadow {
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
}.app-count {
    position: relative;
}
.noa {
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
    color: #464255;
}.waveImg {
    position: absolute;
    right: 0;
    top: 7px;
    width: 40px;
    height: 40px;
}.app-count .possPerc {
    color: #5B9E7B;
}.app-count span {
    display: block;
    font-weight: 500;
    padding-top: 30px;
    font-size: 18px;
    line-height: 27px;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.digitalBox .application {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
    color: #464255;
    padding-top: 10px;
}




  .completed-side {
    width: 100%;
    max-width: 25%;
    border-right: 1px solid #d8d6dd;
  }
 
  p.total-numbers {
     margin: 0; 
    margin: 20px 0px 0px;
    font-size: 24px;
    font-weight: 700;
  }
  .completed-side:last-child {
    border-right: none;
  }
  .completed-side {
    width: 100%;
    max-width: 23%;
    border-right: 1px solid #d8d6dd;
  }
 .completed-button {
    color: #5b9e7b;
    background: rgba(91, 158, 123, 0.15);
  }
  .progress-button {
    color: #ffbb54;
    background: rgba(255, 187, 84, 0.15);
  }
  .stopped-button {
    color: #ff5b5b;
    background: #ffd5d5;
  }
  .not-stoped {
    color: #5b9e7b;
    background: rgba(185, 205, 231, 0.15);
  }
  .d-btn{
    border: none;
    padding: 8px 17px;
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    column-gap: 12px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 14px;
    font-weight: 500;
    font-size: 14px;
  }




  .subscribers-detail {
    width: 100%;
    max-width: 100%;
    border: 2px solid #eceaf3;
    padding: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;
    border-radius: 24px;
  }

  .completed-side {
    width: 100%;
    max-width: 25%;
    border-right: 1px solid #d8d6dd;
  }
  .subscribers-detail.gold .completed-side{
    max-width: 30%;
}
  p.tnumbers {
    margin: 12px 0px 12px;
    font-size: 24px;
    font-weight: 700;
  }
  .completed-side:last-child {
    border-right: none;
  }
  .completed-side {
    width: 100%;
    max-width: 23%;
    border-right: 1px solid #d8d6dd;
  }
  .completed-side span {
    color: #aca6be;
    font-size: 18px;
    font-weight: 500;
  }
  .inner-btns button {
    outline: none;
    background-color: #fff;
    border: 1px solid #d8d6dd;
    padding: 5px 12px;
    color: #aca6be;
    font-size: 12px;
    border-radius: 12px;
    cursor: pointer;

}
  .inner-btns {
    display: flex;
    column-gap: 12px;
    /* justify-content: center; */
  }
  .h-cafeside{
   height: 1220px !important;
  }

  .LineChartContainer{
    border: 2px solid #eceaf3;;
    border-radius: 12px;
    margin-top: 25px;
  }
  .barChartContainer{
    border: 2px solid #eceaf3;;
    border-radius: 12px;
    margin-top: 25px;
  }

  .cafeTopPara{
    color: rgba(70, 66, 85, 0.50);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 7px;
  }