* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-size: 13px;
  line-height: 2;
  background-color: #f7f9fb;
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.5;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}
li {
  list-style-type: none;
}
