#page-wrapper {
  background-color: #f3f3f4;
}

.recent-right {
  display: flex;
  column-gap: 10px;
}

.b-shadow {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
}
.brandImgs {
  width: 50px;
  height: 50px;
  border: 1px solid grey;
  border-radius: 25px;
  object-fit: contain;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0px;
  padding: 0px;
}

.navbar-fixed-top,
.navbar-static-top {
  background: #fff;
}

/* dashborad top starts*/
.d-top {
  background: #fdfdfd;
  border-radius: 24px;
  padding: 12px 15px;
  margin: 30px 20px;
}

.d-top-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addImg {
  width: 80px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
}

.channelBox {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.d-top-left .channelBox span {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.38px;
  color: #384c5f;
  display: block;
}

.d-top-left .channelBox p {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.38px;
  color: #384c5f;
  padding-bottom: 4px;
}

.d-top-right {
  display: flex;
  justify-content: flex-end;
}

.d-top .d-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-top .d-row .cstm-col-md-6 {
  width: 100%;
  max-width: 50%;
}

/* dashborad top end*/
.wrapper__content .channel__left {
  background: #fdfdfd;
  border-radius: 24px;
  padding: 30px 0px 0px;
}

.ch-head p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
}

.ch-head span {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.channel__top {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* .channel__left .channer_ul {
  list-style: none;
  height: 625px;
  overflow-y: auto;
} */
.b-ad-dash {
  height: 641px;
  overflow-y: scroll;
}

.channel__left .channer_ul li {
  background-color: #fff;
  padding: 14px 20px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 2px solid transparent;
}

.channel__left .channer_ul li:hover {
  background: linear-gradient(121.56deg, #fcfcfc 8.05%, #f5f4f9 82.51%);
  border-left: 2px solid #304e75;
}

.channer_ul li .li_left {
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;
  width: 100%;
  max-width: 85%;
  align-items: center;
}

.channer_ul li .li_left .m-loc p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #464255;
}

.channer_ul li .li_left .m-loc span {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.wrapper__content .m-row {
  display: flex;
  margin: unset;
  justify-content: space-between;
  margin-left: 20px;
}

/* cafe right side */

.wrapper__content .custom-col-4 {
  width: 100%;
  max-width: 25%;
  background: #fdfdfd;
  border-radius: 24px;
  position: relative;
}

.custom-col-brandAndAdd {
  width: 100%;
  max-width: 74%;
  position: relative;
}

label {
  margin-bottom: 0px;
}

.alibtmdiv {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 24px 24px;
  width: 100%;
}

.alibtmdiv .new-channel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  column-gap: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #a3a3a3;
  cursor: pointer;
}

/* modal */
/* Modal Content */
#m-modal-content {
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;

  background: #fdfdfd;
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
}

/* Close Button */
.close {
  font-size: 20px;
  font-weight: bold;
  color: #666;
  cursor: pointer;
}

/* Overlay */

.modalTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.modalTop h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
}

.forminput {
  padding: 20px;
}

.forminput .inputGroup label {
  display: block;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.forminput .inputGroup {
  margin-bottom: 12px;
}

.innerInput {
  position: relative;
}

.forminput .inputGroup .innerInput img {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.forminput .inputGroup input {
  width: 100%;
  padding: 10px 24px;
  background: #ffffff;
  border-radius: 15px;
  outline: none;
  border: 1px solid #eceaf3;
  padding-left: 35px;
}

.forminput .inputGroup .pl {
  padding-left: 12px;
}

.cancelbtn,
.createbtn {
  width: 100%;
  max-width: 50%;
  outline: none;
  border: none;
  padding: 18px;
  font-weight: 500;
  font-size: 14px;
  color: #a3a3a3;
}

.cancelbtn {
  border-right: 1px solid #d5dfe9;
}

.modalBtn {
  display: flex;
}

.errorMsg {
  border: 1px solid #fd3c4f !important;
  position: relative;
}

.errorMsg::placeholder {
  color: #f05b5b !important;
}

#li__right {
  position: relative;
}

.brandDropDown {
  position: absolute;
  right: 0;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  background-color: #fff;
  width: 100%;
  min-width: 190px;
}

.brandDropDown p {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  padding: 15px 0px 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #464255;
}

.brandDropDown p.createdon,
.brandDropDown p span {
  display: block;
  padding-left: 20px;
}

.brandDropDown p span:first-child {
  font-size: 8px;
  line-height: 12px;
  color: #a3a3a3;
  padding-bottom: 6px;
}

button {
  outline: none;
  border: none;
}

.brandDropDown p svg {
  margin-right: 14px;
}

.brandDropDown p:hover {
  background: linear-gradient(121.56deg, #fcfcfc 8.05%, #f5f4f9 82.51%);
}

.brandDropDown p.textred {
  color: #fd3c4f;
  font-weight: 600;
}

.b-date {
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
  margin-bottom: 12px;
  width: 11%;
  margin-top: 2px;
}
.brandAddWraper {
  padding: 27px 15px 15px;
  background: #fdfdfd;
  border-radius: 24px;
  margin-bottom: 12px;
}

.brandAddWraper:last-child {
  margin-bottom: 0px;
}

.brandAddWraper {
  display: flex;
  align-items: center;
}

.brandAddWraper .addCol {
  width: 100%;
  display: flex;
  column-gap: 12px;
}
.addDesc {
  width: 115px;
  margin-top: 10px;
  height: 45px;
}

.brandAddWraper .addCol .addDesc span {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
  display: block;
  width: 115px !important;
}

.brandAddWraper .addCol .addDesc p {
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #464255;
  max-width: 115px !important;
}

.Amount span {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
  display: block;
}

.Amount p {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #2db27c;
}

.qr-col p {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.editsIcon {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

img.b-menu {
  width: 50%;
  margin-bottom: 14px;
}

.brandDropDown .enable {
  font-size: 12px;
  line-height: 18px;
  color: #464255;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2db27c;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2db27c;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.addCol.catagory-col {
  width: 100%;
  max-width: 208px;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 25px;
}
.example {
  width: 100%;
  gap: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 500px;
}

.b-a-dash {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: -12px;
  right: 38px;
}
.img img {
  width: 100%;
  height: 100%;
  margin-left: 20px;
  margin-bottom: 10px;
}

.img {
  margin: 0 auto;
  width: 240px;
  height: 200px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.Add-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.mide {
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
  text-align: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
.img-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3px;
  margin-right: 20px;
}
.test-add {
  width: 145px;
  height: 100px;
  margin-top: 10px;
}
