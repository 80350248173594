.cafe_side {
  padding: 30px;
  background: #fdfdfd;
  border-radius: 24px;
}

.devceIconStyle {
  cursor: pointer;
}

.wrapper__content .cstm-col-md-4 {
  width: 100%;
  max-width: 25%;
  background: #fdfdfd;
  border-radius: 24px;
  position: relative;
}

.wrapper__content .device-cstm-col-md-8 {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.cafe_side .cafe-top {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cafe_side .cafe-top .cafe-top-left p {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
  padding-bottom: 8px;
}

.cafe_side .cafe-top .cafe-top-left span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
  display: flex;
  column-gap: 8px;
}

.cafe_side .cafe-top-right .search-box {
  position: relative;
  display: inline-block;
  height: 45px;
  width: 50%;
}

.cafe_side .cafe-top-right .search-input {
  padding-left: 18px;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background: #f5f5f5;
  border-radius: 18px;
}

.cafe_side .cafe-top-right .search-input::placeholder {
  color: #a3a3a3;
  font-size: 13px;
}

.cafe_side .cafe-top-right .search-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.cafe_side .cafe-top-right {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
}

.cafe_side .devicesTable table {
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
}

.cafe_side .devicesTable th,
.cafe_side .devicesTable td {
  padding: 15px 10px;
  text-align: left;
  border-bottom: unset;
}
.cafe_side .devicesTable tr {
  border-bottom: 1px solid #ddd;
}

.cafe_side .devicesTable th {
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
  font-weight: 400;
}

.cafe_side .devicesTable td {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #464255;
}

.cafe_side .devicesTable table {
  background-color: #fdfdfd;
}

td:last-child button {
  margin-left: 5px;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.devicesTable table tbody td .addDevices {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #c4c4c4;
}

.devicesTable table tbody td label,
.devicesTable table thead td label {
  margin-bottom: 0px;
}
.devicesTable table tbody tr {
  border-left: 5px solid transparent;
}
.devicesTable table tbody tr:hover {
  border-left: 5px solid #417ec0;
  background-color: #fff;
}

.devicesTable table tbody tr:first-child {
  background-color: #fafcff;
}

label {
  margin-bottom: 0px;
}

.paid-button {
  border: none;
  color: #5b9e7b;
  padding: 8px 17px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 14px;
  font-weight: 500;
  font-size: 14px;
  background: rgba(91, 158, 123, 0.15);
}

.pending-button {
  border: none;
  color: #ff5b5b;
  padding: 8px 17px;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 14px;
  font-weight: 500;
  font-size: 14px;
  background: #ffd5d5;
  font-weight: 700;
}

.cafe_side .devicesTable td.imei {
  font-style: normal;
  font-weight: 500;
  color: #417ec0;
}
.moreinfo img {
  margin-right: 7px;
}
#overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.moreInfoModal {
  background: #fdfdfd;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  padding: 30px;
  position: absolute;
  z-index: 100;
  width: 320px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.devicesTable {
  position: relative;
}
.cheImg {
  text-align: right;
}

.dev-modal span,
.mod-col span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.modalnumber {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #464255;
}

.moreInfoWraper {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 20px;
}

.mod-col {
  width: 100%;
  max-width: 40%;
}

.moreinfo {
  cursor: pointer;
}

.Devices__pagingation .showData p {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #304e75;
}

.Devices__pagingation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 35px;
  right: 35px;
}

.Devices__pagingation .pagnum a {
  background-color: #304e75;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Devices__pagingation .pagnum {
  display: flex;
  column-gap: 7px;
}

.Devices__pagingation .pagnum a:first-child {
  background: rgba(48, 78, 117, 0.15);
  color: #304e75;
}
.cafe_side .devicesTable tbody td span {
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #a3a3a3;
}
.cafe_side .devicesTable .th-one .cafe_side .devicesTable .td-one {
  width: 20%;
}
.cafe_side .devicesTable table tbody tr {
  position: unset;
}
.cafe_side .devicesTable .th-two,
.cafe_side .devicesTable .td-two {
  width: 15%;
}

.cafe_side .devicesTable .th-three,
.cafe_side .devicesTable .td-three {
  width: 15%;
}

.cafe_side .devicesTable .th-four,
.cafe_side .devicesTable .td-four {
  width: 15%;
}
.cafe_side .devicesTable .th-five,
.cafe_side .devicesTable .td-five {
  width: 15%;
}
.cafe_side .devicesTable .th-six,
.cafe_side .devicesTable .td-six {
  width: 15%;
}
.no-device {
  position: absolute;
  left: 0;
  right: 0;
  top: 353px;
  text-align: center;
}
