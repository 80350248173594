#page-wrapper {
  background-color: #f3f3f4;
}
.b-shadow {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08);
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0px;
  padding: 0px;
}
.navbar-fixed-top,
.navbar-static-top {
  background: #fff;
}
.timeSlotStyle{
  background-color: #f9f9f9 !important;
  border-bottom: 1px solid #eceaf3 !important;
}
.timeSlotClearStyle{
  background-color: #fff !important;
  border-bottom: none!important;
}
.d-top {
  background: #fdfdfd;
  border-radius: 24px;
  padding: 12px 15px;
  margin: 30px 20px;
}

.d-top-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channelBox {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.d-top-left .channelBox span {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.38px;
  color: #384c5f;
  display: block;
}

.d-top-left .channelBox p {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.38px;
  color: #384c5f;
  padding-bottom: 4px;
}

.channelBtn {
  width: 100%;
  max-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2db27c;
  column-gap: 12px;
  color: #fff;
  border-radius: 15px;
  font-weight: 700;
  font-size: 14px;
  margin-left: 15px;
  padding: 8px 0;
}

.channelBtn:hover {
  color: #fff;
  background-color: #446789;
}

.d-top-right {
  display: flex;
  justify-content: flex-end;
}

.d-top .d-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-top .d-row .cstm-col-md-6 {
  width: 100%;
  max-width: 50%;
}

/* dashborad top end*/
.wrapper__content .channel__left {
  background: #fdfdfd;
  border-radius: 24px;
  padding: 30px 0px 0px;
}

.ch-head p {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
}

.ch-head span {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.channel__top {
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.channel__left .channel__top .c-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.channel__left .channel__top .c-right img {
  width: 80%;
  height: 80%;
}

.channel__left .channer_ul {
  list-style: none;
}

.channel__left .channer_ul li {
  background-color: #fff;
  padding: 14px 20px;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-left: 2px solid transparent;
}

.channel__left .channer_ul li:hover {
  background: linear-gradient(121.56deg, #fcfcfc 8.05%, #f5f4f9 82.51%);
  border-left: 2px solid #304e75;
}

.channer_ul li .li_left {
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;
  width: 100%;
  max-width: 85%;
  align-items: center;
}

.channer_ul li .li_left .m-loc p {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  color: #464255;
}

.channer_ul li .li_left .m-loc span {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
}

.wrapper__content .m-row {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
}

/* cafe right side */

.wrapper__content .custom-col-4 {
  width: 100%;
  max-width: 25%;
  background: #fdfdfd;
  border-radius: 24px;
  position: relative;
}

.wrapper__content .custom-col-8 {
  width: 100%;
  max-width: 100%;
  padding-bottom: 100px;
  margin: 0 20px;
}

label {
  margin-bottom: 0px;
}

.btmDiv {
  position: absolute;
  bottom: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0px 0px 24px 24px;
  width: 100%;
}

.btmDiv .new-channel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  column-gap: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #a3a3a3;
}

/* modal */

/* Close Button */
.close {
  font-size: 20px;
  font-weight: bold;
  color: #666;
  cursor: pointer;
}

/* Overlay */
button {
  outline: none;
  border: none;
}

.custom-col-8 .b-date {
  font-size: 16px;
  line-height: 24px;
  color: #a3a3a3;
}

.custom-col-8 {
  padding: 15px;
  background: #fdfdfd;
  border-radius: 24px;
}

.editAdd {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #464255;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Flex item styles */
.flex-item {
  flex-basis: 33.33%;
  padding: 20px;
}

/* Input field styles */
.flex-item input {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #eceaf3;
  border-radius: 18px;
  outline: none;
}

/* Body / Paragraph */
.flex-item input::placeholder {
  font-size: 14px;
  line-height: 26px;
  color: #a3a3a3;
}

/* Image styles */
.flex-image {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
}

.flex-item label {
  font-size: 12px;
  line-height: 18px;
  color: #a3a3a3;
  margin-bottom: 5px;
  display: block;
}

.InputGroup {
  margin-bottom: 10px;
}

.selectwrapper select {
  outline: none;
  border: none;
  padding-left: 10px;
  padding-right: 15px;
  width: 100%;
  height: 46px;
  color: #181820;
  appearance: none;
  /* background: url(../selectDrop.png); */
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: right;
  background-origin: content-box;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #eceaf3;
  border-radius: 18px;
}

.css-13cymwt-control {
  outline: none;
  border: none;
  padding-left: 10px;
  padding-right: 15px;
  width: 100%;
  height: 46px;
  color: #181820;
  appearance: none;
  /* background: url(../selectDrop.png); */
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: right;
  background-origin: content-box;
  margin-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #eceaf3;
  border-radius: 18px !important;
}
.selectwrapper {
  position: relative;
}

.selectwrapper .absoluteImg {
  position: absolute;
  left: 9px;
  bottom: 19px;
  width: 7%;
}

.InputGroup textarea {
  background: #ffffff;
  border: 1px solid #eceaf3;
  border-radius: 18px;
  resize: none;
  font-size: 12px;
  line-height: 26px;
  color: #a3a3a3;
  padding: 4px 12px 0px;
  outline: none;
  width: 100%;
}

.inutField {
  position: relative;
}

.inutField input {
  padding-left: 44px;
}

.dolarimg {
  position: absolute;
  left: 12px;
  top: 20px;
  transform: translateY(-50%);
}

.browseBtn {
  background: linear-gradient(
      0deg,
      rgba(237, 237, 237, 0.2),
      rgba(237, 237, 237, 0.2)
    ),
    #fbfdfe;
  border-radius: 0px 0px 5px 5px;
  border: none;
  padding: 12px 0px;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  color: #304e75;
  width: 100%;
}

.flex-item .mb {
  margin-bottom: 12px;
}

.flex-item input[type="date"],
.flex-item input[type="text"] {
  font-size: 16px;
  line-height: 19px;
  color: #304e75;
  font-size: 12px;
}
.flex-item .dropzone {
  min-height: 250px;
  border: 2px dashed #ccc;
}
.dz-default.dz-message {
  font-size: 12px;
}
/* The modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
button.createbtn {
  border-bottom-right-radius: 24px;
}

button.cancelbtn {
  border-bottom-left-radius: 24px;
}
.forminput.newBrand .dropzone {
  border: 0.994083px dashed rgba(0, 0, 0, 0.25);
  border-radius: 24px;
  margin-top: 20px;
}

.timeSlot {
  width: 100%;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #eceaf3;
  padding: 10px;
  margin-bottom: 10px;
}
.ts-table {
  width: 100%;
}

.ts-table thead tr th,
.ts-table tbody tr td {
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  color: #a3a3a3;
  padding: 15px 9px;
  text-align: center;
}
.ts-table thead tr th {
  padding-bottom: 0px;
}
.ts-table tbody tr {
  border-bottom: 1px solid #eceaf3;
}
.ts-table tbody tr:last-child {
  border-bottom: unset;
}
.fallback {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.fallback img {
  width: 200px;
  height: 180px;
}
.error {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #fd3c4f;
  margin-left: 5px;
}
